<!--
  PACKAGE_NAME : src/pages/cc/cti/team-category/modal
  FILE_NAME : modal-team-category.vue
  AUTHOR : hmlee
  DATE : 2024-07-02
  DESCRIPTION : 1차 or 2차 그룹 등록 모달 컴포넌트
-->
<template>
  <transition>
    <table class="table_form line-bin">
      <caption>
        <strong>{{ $_msgContents('COMPONENTS.GROUP', {defaultValue: '그룹'}) }}</strong>
      </caption>
      <colgroup>
        <col style="width:110px;">
        <col style="width:auto;">
      </colgroup>

      <tbody>
      <tr>
        <th scope="row">
          <label for="label5">
            {{ modalData.depth }}
            {{
              $_msgContents('CC.WORD.TH_GROUP_NAME', {defaultValue: '차 그룹명'})
            }}
            <span class="icon_require"></span>
          </label>
        </th>
        <td>
          <DxTextBox
            v-model="formData.ctgNm"
            :max-length="limitNumberTexts.maxLengths.ctgNm"
            :styling-mode="config.stylingMode"
            class="mar_ri10"
            :width="250"
            @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'ctgNm')"
          >
            <DxValidator validation-group="validationGroupModal">
              <DxAsyncRule
                :reevaluate="false"
                :validationCallback="checkDuplicateCtgNm"
                :message="$_msgContents('CC.MESSAGE.DUPLICATE_NAME', { defaultValue: '이미 등록되어 있는 명칭입니다.'})"
              />
              <DxRequiredRule
                :message="$_msgContents('CC.MESSAGE.REQUIRED_GROUP_NAME', { defaultValue: '그룹명은 필수입니다.'})"
              />
            </DxValidator>
          </DxTextBox>
          {{
            limitNumberTexts.textLengths.ctgNm ? limitNumberTexts.textLengths.ctgNm : (formData.ctgNm ? formData.ctgNm.length : 0)
          }}/{{ limitNumberTexts.maxLengths.ctgNm }}자
        </td>
      </tr>
      <tr>
        <th scope="row">
          <label for="label3">{{ $_msgContents('COMPONENTS.USE_STATUS', {defaultValue: '사용여부'}) }}</label>
        </th>
        <td>
          <DxSwitch @value-changed="onChangedViewFl($event)" :value="getViewFl"/>
        </td>
      </tr>
      </tbody>
    </table>
  </transition>
</template>

<script>
import {DxTextBox} from 'devextreme-vue/text-box';
import {DxValidator, DxRequiredRule, DxAsyncRule} from 'devextreme-vue/validator';
import {isSuccess} from "@/plugins/common-lib";
import DxSwitch from "devextreme-vue/switch";

export default {
  components: {
    DxSwitch,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxAsyncRule,
  },
  props: {
    modalData: Object,
  },
  watch: {},
  data() {
    return {
      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
      },
      formData: {
        id: null,
        parentId: null,
        ctgNm: null,
        depth: null,
        viewFl: 'Y',
        agtteamCtgOrd: null,
        regId: this.$store.getters.getLoginId,
      },
      limitNumberTexts: {
        textLengths: {},
        maxLengths: {
          ctgNm: 20,
        },
      },
    }
  },
  computed: {
    /** @description: 사용여부 */
    getViewFl() {
      return this.formData.viewFl === 'Y';
    },
  },
  methods: {
    /** @description : 카테고리명 중복 체크 */
    async checkDuplicateCtgNm(e) {
      const params = {ctgNm: e.value, parentId: this.formData.parentId};
      const payload = {
        actionname: 'CC_AGTTEAM_CATEGORY_LIST',
        data: params,
      }

      const res = await this.CALL_CC_API(payload);
      if (isSuccess(res) && res.data.data.length > 0) { //그룹명 중복 데이터가 존재하면
        return false;
      }else {
        return true;
      }
    },
    /** @description: 사용여부 이벤트 */
    onChangedViewFl(e) {
      if (e.value) this.formData.viewFl = 'Y';
      else this.formData.viewFl = 'N';
    },
    /** @description : 라이프사이클 created 호출되는 메서드 */
    createdData() {
      this.formData = {...this.formData, ...this.modalData};
    },
    /** @description : 라이프사이클 mounted 호출되는 메서드 */
    mountedData() {
      this.$_eventbus.$on('ModalTeamCategory:onSaveData', async (e, resolve) => {
        //validation 체크
        if (!e.validationGroup.validate().isValid) {
          return false;
        }

        //순서 저장
        this.formData.agtteamCtgOrd = this.modalData.agtteamCtgOrd + 1;

        const payload = {
          actionname: 'CC_AGTTEAM_CATEGORY_MERGE',
          data: [this.formData],
        }

        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}))
          resolve(200);
        }
      });
    },
    /** @description : 라이프사이클 beforeDestroyed 호출되는 메서드 */
    beforeDestroyedData() {
      this.$_eventbus.$off('ModalTeamCategory:onSaveData');
    },
  },
  created() {
    this.createdData();
  },
  mounted() {
    this.mountedData();
  },
  beforeDestroy() {
    this.beforeDestroyedData();
  },
  destroyed() {
  },
}
</script>

<style scoped>
</style>